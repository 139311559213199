import MicroModal from 'micromodal';
import Swiper from 'swiper';
import { Pagination, A11y } from 'swiper/modules';

MicroModal.init();

const swiper = new Swiper('.swiper', {
	modules: [Pagination, A11y],
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
});

// Função principal para trocar imagens
function changeImage(imageIds, currentIndex, fadeTime, intervalTime) {
	const currentImageId = imageIds[currentIndex];
	const currentImage = document.getElementById(currentImageId);

	// Oculta a imagem atual suavemente
	fadeOut(currentImage, function () {
		// Após a conclusão da transição de opacidade, atualiza para a próxima imagem
		currentIndex = (currentIndex + 1) % imageIds.length;

		const nextImageId = imageIds[currentIndex];
		const nextImage = document.getElementById(nextImageId);

		// Exibe a próxima imagem suavemente
		fadeIn(nextImage);

		// Chama a função novamente após X segundos (1000ms = 1 segundo)
		setTimeout(() => changeImage(imageIds, currentIndex, fadeTime, intervalTime), intervalTime);
	});
}

// Função para ocultar elemento com uma transição suave de opacidade
function fadeOut(element, callback) {
	let opacity = 1;
	const fadeEffect = setInterval(function () {
		if (opacity > 0) {
			opacity -= 0.1;
			element.style.opacity = opacity;
		} else {
			clearInterval(fadeEffect);
			element.style.display = 'none'; // Oculta completamente o elemento
			if (typeof callback === 'function') {
				callback();
			}
		}
	}, 30);
}

// Função para exibir elemento com uma transição suave de opacidade
function fadeIn(element) {
	element.style.display = 'block'; // Exibe o elemento
	let opacity = 0;
	const fadeEffect = setInterval(function () {
		if (opacity < 1) {
			opacity += 0.1;
			element.style.opacity = opacity;
		} else {
			clearInterval(fadeEffect);
		}
	}, 30);
}

// Inicializa o loop para a primeira seção
const imageIds1 = ['image1', 'image2'];
let currentIndex1 = 0;
changeImage(imageIds1, currentIndex1, 5000, 5000); // Altere os tempos conforme necessário

// Inicializa o loop para a segunda seção
const imageIds2 = ['image3', 'image4'];
let currentIndex2 = 0;
changeImage(imageIds2, currentIndex2, 5000, 5000); // Altere os tempos conforme necessário

// Animação fade-in

window.addEventListener('scroll', function () {
	var items = document.querySelectorAll('.animacao-item');

	for (var i = 0; i < items.length; i++) {
		var item = items[i];
		var itemTop = item.getBoundingClientRect().top;

		if (itemTop < window.innerHeight) {
			item.classList.add('show');
		}
	}
});

window.addEventListener('scroll', function () {
	var items = document.querySelectorAll('.animacao-item-2');

	for (var i = 0; i < items.length; i++) {
		var item = items[i];
		var itemTop = item.getBoundingClientRect().top;

		if (itemTop < window.innerHeight) {
			item.classList.add('show');
		}
	}
});
